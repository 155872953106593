import axios from "axios";

export class RegistrationForm {
    /** @type{string} */
    Name = null;
    /** @type{string} */
    WebSite = null;
    /** @type{string} */
    Description = null;
    /** @type{string} */
    Sector = null;
    /** @type{string} */
    Simplify = null;
    /** @type{string} */
    HasInvestment = null;
    /** @type{string} */
    Level = null;
    /** @type{string} */
    LevelDetail = null;
    /** @type{string} */
    Indorsement = null;
    /** @type{string} */
    IncomeGeneration = null;
    /** @type{string} */
    FullTimeEmployee = null;
    /** @type{string} */
    TotalEmployee = null;
    /** @type{string} */
    Fullname = null;
    /** @type{string} */
    ProjectRole = null;
    /** @type{string} */
    TimeCommitment = null;
    /** @type{string} */
    Email = null;
    /** @type{string} */
    Phone = null;
    /** @type{string} */
    SocialMedia = null;
    /** @type{string} */
    EducationLevel = null;
    /** @type{string} */
    LastGraduateUni = null;
    /** @type{string} */
    LastGraduateProgram = null;
    /** @type{string} */
    Gender = null;
    /** @type{string} */
    Birtyear = null;
    /** @type{string} */
    CountryCity = null;
    /** @type{string} */
    TeamDetail = null;
}

export class ContactForm {
    /** @type{string} */
    Name = null;
    /** @type{string} */
    Email = null;
    /** @type{string} */
    Message = null;
    /** @type{string} */
    Subject = null;
}

const client = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});


export class Api {
    /**
     * 
     * @param {RegistrationForm} form 
     */
    static async createRegistration(form) {
        var formdata = new FormData();
        for (var k in form) {
            formdata.append(k, form[k]);
        }
        return await client.request({
            method: "post",
            url: "/register",
            data: formdata,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }).then(r=>r.data).catch(console.error) || {
            success: false
        };
    }
    /**
    * @param {ContactForm} form
     */
    static async createContact(form) {
        return await client.post("/contact", form).then(r=>r.data).catch(console.error) || {
            success: false
        };
    }
}