import React, { useEffect } from 'react';



export function PageSlider() {

  return (<>
    {/*Slider Start*/}
    <section className="p-0 no-transition cursor-light" id="home">
      <h2 className="d-none">hidden</h2>
      <div className="rev_slider_wrapper fullscreen-container" data-alias="megaone-agency-1" data-source="gallery" id="rev_slider_17_1_wrapper" style={{ background: 'transparent', padding: '0px' }}>
        {/* START REVOLUTION SLIDER 5.4.8.1 fullscreen mode */}
        <div className="rev_slider fullscreenbanner" data-version="5.4.8.1" id="rev_slider_17_1" style={{ display: 'none' }}>
          <ul>
            {/* SLIDE  */}
            <li data-description data-easein="default" data-easeout="default" data-hideafterloop={0} data-hideslideonmobile="off" data-index="rs-43" data-masterspeed="default" data-param1 data-param10 data-param2 data-param3 data-param4 data-param5 data-param6 data-param7 data-param8 data-param9 data-rotate={0} data-saveperformance="off" data-slotamount="default" data-title="Slide" data-transition="fade">
              {/*OVERLAY*/}
              <div className="gradient-bg1 bg-overlay" />
              {/* LAYER NR. 3 */}
              <div className="tp-caption   tp-resizeme" data-frames="[{&quot;delay&quot;:220,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;rZ:339;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['-26','-26','83','83']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['inherit','inherit','inherit','inherit']" data-type="image" data-voffset="['98','98','60','60']" data-whitespace="nowrap" data-width="none" data-x="['left','left','left','left']" data-y="['bottom','bottom','bottom','bottom']" id="slide-43-layer-5" style={{ zIndex: 7 }}>
                <div className="rs-looped rs-slideloop" data-easing data-speed={5} data-xe={0} data-xs={0} data-ye={15} data-ys={-15}><img alt="" data-hh="['87px','87px','87px','87px']" data-no-retina data-ww="['44px','44px','44px','44px']" src="img/shape-6.png" />
                </div>
              </div>
              {/* LAYER NR. 4 */}
              <div className="tp-caption   tp-resizeme" data-basealign="slide" data-frames="[{&quot;delay&quot;:210,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;rZ:358;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['700','700','700','530']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['inherit','inherit','inherit','inherit']" data-type="image" data-voffset="['148','148','148','148']" data-whitespace="nowrap" data-width="none" data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']" id="slide-43-layer-6" style={{ zIndex: 8 }}>
                <div className="rs-looped rs-slideloop" data-easing="Power0.easeIn" data-speed={5} data-xe={0} data-xs={0} data-ye={20} data-ys={0}><img alt="" data-hh="['87px','87px','87px','87px']" data-no-retina data-ww="['24px','24px','24px','24px']" src="img/shape-5.png" />
                </div>
              </div>
              {/* LAYER NR. 5 */}
              <div className="tp-caption   tp-resizeme" data-fontsize="['20','20','20','20']" data-frames="[{&quot;delay&quot;:220,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['275','275','171','120']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['inherit','inherit','inherit','inherit']" data-type="image" data-voffset="['270','270','261','190']" data-whitespace="nowrap" data-width="none" data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']" id="slide-43-layer-7" style={{ zIndex: 9 }}>
                <div className="rs-looped rs-wave" data-angle={0} data-origin="50% 50%" data-radius="15px" data-speed={12}><img alt="" data-hh="['67px','67px','67px','67px']" data-no-retina data-ww="['68px','68px','68px','68px']" src="img/shape-4.png" /></div>
              </div>
              {/* LAYER NR. 6 */}
              <div className="tp-caption   tp-resizeme" data-frames="[{&quot;delay&quot;:190,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['413','413','268','204']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['inherit','inherit','inherit','inherit']" data-type="image" data-voffset="['-205','-205','-259','-145']" data-whitespace="nowrap" data-width="none" data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']" id="slide-43-layer-8" style={{ zIndex: 10 }}>
                <div className="rs-looped rs-rotate" data-easing="Power0.easeIn" data-enddeg={360} data-origin="50% 50%" data-speed={15} data-startdeg={0}><img alt="" data-hh="['69px','69px','69px','69px']" data-no-retina data-ww="['67px','67px','67px','67px']" src="img/shape-3.png" /></div>
              </div>
              {/* LAYER NR. 7 */}
              <div className="tp-caption   tp-resizeme" data-frames="[{&quot;delay&quot;:190,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['436','436','259','145']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['inherit','inherit','inherit','inherit']" data-type="image" data-voffset="['204','204','96','73']" data-whitespace="nowrap" data-width="none" data-x="['left','left','left','left']" data-y="['top','top','top','top']" id="slide-43-layer-9" style={{ zIndex: 11 }}>
                <div className="rs-looped rs-pendulum" data-easing data-enddeg={20} data-origin="50% 50%" data-speed={12} data-startdeg={-20}><img alt="" data-hh="['52px','52px','52px','52px']" data-no-retina data-ww="['51px','51px','51px','51px']" src="img/shape-1.png" />
                </div>
              </div>
              {/* LAYER NR. 8 */}
              <div className="tp-caption   tp-resizeme" data-basealign="slide" data-frames="[{&quot;delay&quot;:170,&quot;speed&quot;:500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['39','39','94','58']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['inherit','inherit','inherit','inherit']" data-type="image" data-voffset="['109','109','64','69']" data-whitespace="nowrap" data-width="none" data-x="['right','right','right','right']" data-y="['top','top','top','top']" id="slide-43-layer-10" style={{ zIndex: 12 }}>
                <div className="rs-looped rs-slideloop" data-easing data-speed={2} data-xe={15} data-xs={0} data-ye={0} data-ys={0}><img alt="" data-hh="['14px','14px','14px','14px']" data-no-retina data-ww="['50px','50px','50px','50px']" src="img/shape-2.png" />
                </div>
              </div>
              {/* LAYER NR. 9 */}
              <div className="tp-caption tp-resizeme gradient-text1" data-fontsize="['70','65','60','50']" data-frames="[{&quot;delay&quot;:660,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;&quot;,&quot;mask&quot;:&quot;x:0px;y:[100%];s:inherit;e:inherit;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power2.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['320','250','0','0']" data-lineheight="['80','75','70','60']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['left','left','center','center']" data-type="text" data-voffset="['-125','-125','-125','-110']" data-whitespace="nowrap" data-width="['650','650','600','500']" data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']" id="slide-43-layer-13" style={{ zIndex: 13, minWidth: '500px', maxWidth: '650px', whiteSpace: 'nowrap', fontSize: '70px', lineHeight: '80px', fontWeight: 800, color: '#ffffff', letterSpacing: '0px', fontFamily: 'Montserrat' }}>
                DİNÇER LOJİSTİK
              </div>
              {/* LAYER NR. 10 */}
              <div className="tp-caption   tp-resizeme" data-fontsize="['70','65','60','50']" data-frames="[{&quot;delay&quot;:1840,&quot;split&quot;:&quot;chars&quot;,&quot;splitdelay&quot;:0.1,&quot;speed&quot;:1000,&quot;split_direction&quot;:&quot;forward&quot;,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;sX:0.8;sY:0.8;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power4.easeOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;nothing&quot;}]" data-height="none" data-hoffset="['320','250','0','0']" data-lineheight="['70','75','70','60']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['left','left','center','center']" data-type="text" data-voffset="['-40','-40','-40','-40']" data-whitespace="nowrap" data-width="['650','650','600','500']" data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']" id="slide-43-layer-11" style={{ zIndex: 14, minWidth: '500px', maxWidth: '650px', whiteSpace: 'nowrap', fontSize: '70px', lineHeight: '70px', fontWeight: 700, color: '#ffffff', letterSpacing: '0px', fontFamily: 'Montserrat' }}>
                STARTUP DEMO DAY
              </div>
              {/* LAYER NR. 11 */}
              <div className="tp-caption   tp-resizeme" data-fontsize="['20','20','18','17']" data-frames="[{&quot;delay&quot;:2360,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;y:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['325','210','0','0']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['left','left','center','center']" data-type="text" data-voffset="['80','75','65','57']" data-whitespace="normal" data-width="['651','550','600','500']" data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']" id="slide-43-layer-14" style={{ zIndex: 15, minWidth: '500px', maxWidth: '651px', whiteSpace: 'normal', fontSize: '20px', lineHeight: '30px', fontWeight: 300, color: '#ffffff', letterSpacing: '0px', fontFamily: 'Roboto' }}>
                Sektörün dijital öncüsü olarak, lojistiğin geleceğine yön veren şirketler arasında olmayı hedefliyoruz. Bunu da Startup'larla başarabileceğimizi biliyoruz.

                Lojistik, tedarik zinciri, perakende, robotik, mobilite, yapay zeka, blockchain, iş zekası gibi alanlarda yenilikçi çözümler sunuyorsan, Dinçer Lojistik Startup Demo Day'e başvurunu bekliyoruz.

              </div>

              {/* Last Registration Date label */}

              {/* LAYER NR. 12 */}
              <div className="tp-caption   tp-resizeme" data-frames="[{&quot;delay&quot;:2970,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;y:50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['325','260','0','0']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['left','left','center','center']" data-type="text" data-voffset="['200','186','176','176']" data-whitespace="nowrap" data-width="['650','650','600','500']" data-x="['center','center','center','center']" data-y="['middle','middle','middle','middle']" id="slide-43-layer-15" style={{ zIndex: 16, whiteSpace: 'nowrap' }}>
                {/* <div style={{
                  marginTop: 50,
                  marginBottom: 20
                }}>
                  <label style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontSize: '1.8rem'
                  }}>
                    Başvurular tamamlandı
                  </label>
                  <br />
                </div> */}
                <a className="btn btn-slider btn-rounded btn-blue btn-hvr-white" href="/register">{"BAŞVURU YAP"}
                  <div className="btn-hvr-setting">
                    <ul className="btn-hvr-setting-inner">
                      <li className="btn-hvr-effect" />
                      <li className="btn-hvr-effect" />
                      <li className="btn-hvr-effect" />
                      <li className="btn-hvr-effect" />
                    </ul>
                  </div>
                </a>
              </div>
              {/* LAYER NR. 13 */}
              <div className="tp-caption   tp-resizeme" data-frames="[{&quot;delay&quot;:990,&quot;speed&quot;:1500,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;x:-50px;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['0','0','-412','-412']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['inherit','inherit','inherit','inherit']" data-type="image" data-visibility="['on','on','off','off']" data-voffset="['-1','-1','72','72']" data-whitespace="nowrap" data-width="none" data-x="['left','left','left','left']" data-y="['middle','middle','middle','middle']" id="slide-43-layer-16" style={{ zIndex: 17 }}>
                <div className="rs-looped rs-slideloop" data-easing data-speed={5} data-xe={15} data-xs={-10} data-ye={0} data-ys={0}><img alt="" data-hh="['604px','510','510','510']" data-no-retina data-ww="['434px','350','350','350']" src="img/vector-art-1.png" /></div>
              </div>
              {/* LAYER NR. 14 */}
              {/* <div className="tp-caption tp-resizeme hide-cursor" data-actions="[{&quot;event&quot;:&quot;click&quot;,&quot;action&quot;:&quot;scrollbelow&quot;,&quot;offset&quot;:&quot;-50px&quot;,&quot;delay&quot;:&quot;&quot;,&quot;speed&quot;:&quot;1200&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-fontsize="['20','20','20','17']" data-frames="[{&quot;delay&quot;:10,&quot;speed&quot;:300,&quot;frame&quot;:&quot;0&quot;,&quot;from&quot;:&quot;opacity:0;&quot;,&quot;to&quot;:&quot;o:1;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;},{&quot;delay&quot;:&quot;wait&quot;,&quot;speed&quot;:300,&quot;frame&quot;:&quot;999&quot;,&quot;to&quot;:&quot;opacity:0;&quot;,&quot;ease&quot;:&quot;Power3.easeInOut&quot;}]" data-height="none" data-hoffset="['0','0','0','0']" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingtop="[0,0,0,0]" data-responsive_offset="on" data-textalign="['inherit','inherit','inherit','inherit']" data-type="text" data-voffset="['25','25','5','5']" data-whitespace="nowrap" data-width="none" data-x="['center','center','center','center']" data-y="['bottom','bottom','bottom','bottom']" id="slide-43-layer-17" style={{ zIndex: 18, whiteSpace: 'nowrap', cursor: 'pointer', fontSize: '20px', lineHeight: '30px', fontWeight: 400, color: '#ffffff', letterSpacing: '0px', fontFamily: 'Montserrat' }}>
                Scroll Down <i className="ml-2 fas fa-long-arrow-alt-down" /></div> */}
            </li>
          </ul>
          <div className="tp-bannertimer tp-bottom" style={{ visibility: 'hidden !important' }} />
        </div>
      </div>{/* END REVOLUTION SLIDER */}
    </section>
    {/*Slider End*/}

  </>);
}
