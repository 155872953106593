import { useEffect } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom";

import HomePage from '../pages/HomePage'
import RegisterPage from '../pages/RegisterPage'
export function AppRouter() {
    useEffect(() => {
        if (window.initialize_page) {
            console.log('initialize_page');
            window.initialize_page();
        }
    }, []);
    return (<>
        <Routes>
            <Route index path="/" element={<HomePage />} />
            <Route path="*" element={<NavigateToHome />} />
            <Route path="/register" element={<RegisterPage />} />
        </Routes>
    </>)
}

function NavigateToHome(){
    useEffect(()=>{
        window.location.href = "/";
    }, []);
    return (<></>);
}