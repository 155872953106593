import React from 'react'

export default function AppFooter() {
    return (
        <>
            {/*Footer Start*/}
            <footer className="footer-style-1 bg-light">
                <div className="container">
                    <div className="row align-items-center">
                        {/*Social*/}
                        <div className="col-md-6">
                            <div className="footer-social">
                                <ul className="list-unstyled">
                                    <li><a target="_blank" className="wow fadeInUp" href="https://www.facebook.com/dincerlojistik"><i aria-hidden="true" className="fab fa-facebook-f" /></a></li>
                                    <li><a target="_blank" className="wow fadeInUp" href="https://www.youtube.com/channel/UCCKSnSG6YUSjtlF0tQiLo_A"><i aria-hidden="true" className="fab fa-youtube" /></a></li>
                                    <li><a target="_blank" className="wow fadeInDown" href="https://www.linkedin.com/company/dincerlojistik/mycompany"><i aria-hidden="true" className="fab fa-linkedin-in" /></a></li>
                                    <li><a target="_blank" className="wow fadeInUp" href="https://www.instagram.com/dincer_lojistik/"><i aria-hidden="true" className="fab fa-instagram" /></a></li>
                                </ul>
                            </div>
                        </div>
                        {/*Text*/}
                        <div className="col-md-6 text-md-right">
                            <a className="company-about fadeIn">© {new Date().getFullYear()} Dinçer Lojistik</a>
                        </div>
                </div>
            </div>
        </footer>
        {/*Footer End*/ }
    {/*Animated Cursor*/ }
    <div id="aimated-cursor">
        <div id="cursor">
            <div id="cursor-loader" />
        </div>
    </div>
    {/*Animated Cursor End*/ }
    {/*Scroll Top Start*/ }
    <span className="scroll-top-arrow"><i className="fas fa-angle-up" /></span>
    {/*Scroll Top End*/ }
        </>
    )
}
