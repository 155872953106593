import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
export default function AppRoot() {
    return (
        <>
            {/* Preloader */}
            <div className="preloader">
                <div className="box" />
            </div>
            {/* Ink Transition */}
            <div className="cd-transition-layer visible opening">
                <div className="bg-layer" />
            </div>
            {/*/Preloader */}
            {/*Header Start*/}
            <header className="cursor-light">
                {/*Navigation*/}
                <nav className="navbar navbar-top-default navbar-expand-lg navbar-gradient nav-icon alt-font">
                    <div className="container">
                        <Link to="/" reloadDocument>
                            <a className="logo link scroll" title="Logo" href="/" >
                                {/*Logo Default*/}
                                <img alt="logo" className="logo-dark default" src="logo/dl_full_w_logo.png" />
                            </a>
                        </Link>
                        {/*Nav Links*/}
                        <div className="collapse navbar-collapse" id="agency">
                            <div className="navbar-nav ml-auto">
                                <a className="nav-link link scroll active" href="/#home">ANASAYFA</a>
                                {/* <a className="nav-link link scroll" href="/#section-1">BAŞVURU</a> */}
                                <a className="nav-link link scroll" href="/#section-2">JÜRİ</a>
                                <a className="nav-link link scroll" href="/#section-3">ÖDÜLLER</a>
                                <a className="nav-link link scroll" href="/#section-4">PROGRAM</a>
                                <a className="nav-link link scroll" href="/#section-6">SPONSORLARIMIZ</a>
                                <a className="nav-link link scroll" href="/#section-5">İLETİŞİM</a>
                                <span className="menu-line"><i aria-hidden="true" className="fa fa-angle-down" /></span>
                            </div>
                            <Link to="/register" reloadDocument>
                                <a className="btn btn-medium btn-rounded btn-transparent-white btn-hvr-white ml-3" href="/register" data-animation-duration={500}>{"BAŞVURU YAP"}
                                    <div className="btn-hvr-setting">
                                        <ul className="btn-hvr-setting-inner">
                                            <li className="btn-hvr-effect" />
                                            <li className="btn-hvr-effect" />
                                            <li className="btn-hvr-effect" />
                                            <li className="btn-hvr-effect" />
                                        </ul>
                                    </div>
                                </a>
                            </Link>
                        </div>
                        {/*Menu Button*/}
                        <button className="fullnav-toggler link" id="full-menu-1" type="button">
                            <span className="line" />
                            <span className="line" />
                            <span className="line" />
                        </button>
                        {/*Slider Social*/}
                        <div className="slider-social">
                            <ul className="list-unstyled">
                                <li className="animated-wrap"><a className="animated-element" href="https://www.facebook.com/dincerlojistik"><i aria-hidden="true" className="fab fa-facebook-f" /></a></li>
                                <li className="animated-wrap"><a className="animated-element" href="https://www.instagram.com/dincer_lojistik/"><i aria-hidden="true" className="fab fa-instagram" /></a></li>
                                <li className="animated-wrap"><a className="animated-element" href="https://www.linkedin.com/company/dincerlojistik/mycompany"><i aria-hidden="true" className="fab fa-linkedin-in" /></a></li>
                            </ul>
                        </div>
                    </div>
                </nav>
                {/*Full menu*/}
                <div className="nav-holder main style-2 alt-font">
                    {/*Menu Button*/}
                    <button className="fullnav-close link" type="button">
                        <span className="line" />
                        <span className="line" />
                        <span className="line" />
                    </button>
                    <div className="container">
                        <div className="shape-left">
                            <nav className="navbar full-menu-navigation left">
                                <ul className="list-unstyled">
                                    <li><a className="link scroll" href="/#home">
                                        <span className="anchor-circle" />
                                        <span className="anchor-text">ANASAYFA</span>
                                    </a></li>
                                    {/* <li><a className="link scroll" href="/#section-1">
                                        <span className="anchor-circle" />
                                        <span className="anchor-text">BAŞVURU</span>
                                    </a></li> */}
                                    <li><a className="link scroll" href="/#section-2">
                                        <span className="anchor-circle" />
                                        <span className="anchor-text">JÜRİ</span>
                                    </a></li>
                                    <li><a className="link scroll" href="/#section-3">
                                        <span className="anchor-circle" />
                                        <span className="anchor-text">ÖDÜLLER</span>
                                    </a></li>
                                    <li><a className="link scroll" href="/#section-4">
                                        <span className="anchor-circle" />
                                        <span className="anchor-text">PROGRAM</span>
                                    </a></li>
                                    <li><a className="link scroll" href="/#section-6">
                                        <span className="anchor-circle" />
                                        <span className="anchor-text">SPONSORLARIMIZ</span>
                                    </a></li>
                                    <li><a className="link scroll" href="/#section-5">
                                        <span className="anchor-circle" />
                                        <span className="anchor-text">İLETİŞİM</span>
                                    </a></li>
                                </ul>
                                <span className="full-menu-dot" style={{ transform: 'scale(0)' }} />
                            </nav>
                            <img alt="shape" src="img/shape-8.png" />
                        </div>
                        <div className="shape-right">
                            <div className="full-menu-detail hide-cursor">
                                <h6 className="title">Bizimle İletişime Geçin</h6>
                                <p><i className="fas fa-user-alt" />
                                    <a className='text-white' target="_blank" href="https://www.google.com/maps/place/Din%C3%A7er+Lojistik+A.%C5%9E./@40.9095285,29.1888852,17z/data=!4m10!1m2!2m1!1zRXNlbnRlcGUsIEVzZW50ZXBlIE1haC4gxLBuw7Zuw7wgQ2FkLiBObzoxNDcgS2FydGFsa3VsZSwgxLBuw7Zuw7wgQ2QuIEQ6MTQuS2F0LCAzNDg3MCBLYXJ0YWwvxLBzdGFuYnVs!3m6!1s0x14cb23bc73d254b9:0x7b1a91e15cca3939!8m2!3d40.909453!4d29.1910474!15sCmZFc2VudGVwZSwgRXNlbnRlcGUgTWFoLiDEsG7Dtm7DvCBDYWQuIE5vOjE0NyBLYXJ0YWxrdWxlLCDEsG7Dtm7DvCBDZC4gRDoxNC5LYXQsIDM0ODcwIEthcnRhbC_EsHN0YW5idWxaXyJdZXNlbnRlcGUgZXNlbnRlcGUgbWFoIGluw7Zuw7wgY2FkIG5vIDE0NyBrYXJ0YWxrdWxlIGluw7Zuw7wgY2QgZCAxNCBrYXQgMzQ4NzAga2FydGFsIGlzdGFuYnVskgERbG9naXN0aWNzX3NlcnZpY2XgAQA!16s%2Fg%2F11cjpbrqjk">
                                        Esentepe, Esentepe Mah. İnönü Cad. No:147 Kartalkule, İnönü Cd. D:14.Kat, 34870 Kartal/İstanbul
                                    </a>
                                </p>
                                <p><i className="fas fa-mobile-alt" /><a className="text-white" href="tel:4442874">444 2 874</a></p>
                                <p><i className="fas fa-envelope" /><a className="mr-3 text-white" href="mailto:info@dincerlojistik.com.tr">info@dincerlojistik.com.tr</a></p>
                            </div>
                            <img alt="shape" src="img/shape-7.png" />
                        </div>
                    </div>
                </div>
                {/*Get Quote Model Popup*/}
                <div className="animated-modal hidden quote-content" id="animatedModal">
                    {/*Heading*/}
                    <div className="heading-area pb-2 mx-570">
                        <span className="sub-title">We are megaone company</span>
                        <h2 className="title mt-2">Lets start your <span className="alt-color js-rotating">project, website</span></h2>
                    </div>
                    {/*Contact Form*/}
                    <form className="contact-form" id="modal-contact-form-data">
                        <div className="row">
                            {/*Result*/}
                            <div className="col-12" id="quote_result" />
                            {/*Left Column*/}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input className="form-control" id="quote_name" name="quoteName" placeholder="Name" required type="text" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" id="quote_contact" name="userPhone" placeholder="Contact #" required type="text" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" id="quote_type" name="projectType" placeholder="Project type" required type="text" />
                                </div>
                            </div>
                            {/*Right Column*/}
                            <div className="col-md-6">
                                <div className="form-group">
                                    <input className="form-control" id="quote_email" name="userEmail" placeholder="Email" required type="email" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" id="quote_address" name="userAddress" placeholder="City / Country" required type="text" />
                                </div>
                                <div className="form-group">
                                    <input className="form-control" id="quote_budget" name="quoteBudget" placeholder="Budget" required type="text" />
                                </div>
                            </div>
                            {/*Full Column*/}
                            <div className="col-md-12">
                                <div className="form-group">
                                    <textarea className="form-control" id="quote_message" name="userMessage" placeholder="Please explain your project in detail." defaultValue={""} />
                                </div>
                            </div>
                            {/*Button*/}
                            <div className="col-md-12">
                                <div className="form-check">
                                    <label className="checkbox-lable">Contact by phone is preffered
                                        <input type="checkbox" name="phone_preffered" />
                                        <span className="checkmark" />
                                    </label>
                                </div>
                                <a className="btn btn-large btn-rounded btn-blue btn-hvr-pink modal_contact_btn" href="javascript:void(0);" id="quote_submit_btn"><i className="fa fa-spinner fa-spin mr-2 d-none" aria-hidden="true" /><b>Send Message</b>
                                    <div className="btn-hvr-setting">
                                        <ul className="btn-hvr-setting-inner">
                                            <li className="btn-hvr-effect" />
                                            <li className="btn-hvr-effect" />
                                            <li className="btn-hvr-effect" />
                                            <li className="btn-hvr-effect" />
                                        </ul>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </form>
                </div>
            </header>
            {/*Header End*/}
        </>
    )
}
