import React from 'react';


export function PageHeader() {

  return (<div>
    <div className='gradient-bg1 bg-overlay' style={{ height: '100vh', marginBottom: '-80vh', position: 'relative', zIndex: -1 }}>

    </div>

  </div>);
}
