import React, { useEffect, useState } from 'react'
import { Api } from '../Api/Api'
import { PageSlider } from '../Layout/PageSlider'
import { toast } from 'react-toastify'
import { Timeline } from 'primereact/timeline';
import { Button as PrimeButton } from 'primereact/button';
import { Card as PrimeCard } from 'primereact/card';

export default function HomePage() {



  const juries = [
    {
      name: "Mustafa Dinçer",
      photo: "img/jury/mustafa-dincer-chairman.jpeg",
      title: "Dinçer Lojistik - Yönetim Kurulu Başkanı"
    },
   {
      name: "Murat Şencan",
      photo: "img/jury/murat-sencan-dincer-cto.jpg",
      title: "Dinçer Lojistik - CTO"
    },
    {
      name: "Hakan Kırımlı",
      photo: "img/jury/hakan-kirimli-n11-director.jpg",
      title: "Doğuş Grubu Yönetim Kurulu Başkanı Danışmanı"
    },
    {
      name: "Arda Kutsal",
      photo: "img/jury/arda_kutsal.png",
      title: "Webrazzi Kurucusu ve CEO’su"
    },
    {
      name: "Arda Berkay Çağın",
      photo: "img/jury/arda-berkay-startup-mentor.jpeg",
      title: "Startup Mentor - Strateji ve İş Geliştirme Danışmanı"
    },
    {
      name: "Emre Ekmekçi",
      photo: "img/jury/emre_ekmekci.jpg",
      title: "Vice Chairman of Turkish E. Com. Ass."
    },
    {
      name: "İmran Gürkan",
      photo: "img/jury/imran_gurkan.jpg",
      title: "CEO & Co-Founder LEAP Investment"
    },
    {
      name: "Olcay Silahlı",
      photo: "img/jury/olcay_silahli.jpeg",
      title: "Fazla CEO'su"
    },
    {
      name: "Filiz Mahir",
      photo: "img/jury/filiz_mahir.jpeg",
      title: "P&G Satın Alma Direktörü"
    },
    
    {
      name: "Zafer Mustafaoğlu",
      photo: "img/jury/zafer_mustafaoglu.jpg",
      title: "Halk Yatırım Genel Müdür Yardımcısı"
    }
  ]

  return (
    <>
      <PageSlider />

      {/* BAŞVURU YAP */}

       <section className="bg-light" id="section-1">
        <div className="container container-fluid">
          
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="heading-area mx-570 pb-lg-5 mb-5">
                <h2 className="title mb-0">BAŞVURU</h2>
              </div>
            </div>
          </div>
          
          <div className="row wow fadeInUp">
          
            <div className="col-lg-4">
              <div className="news-item" style={{ height: '100%' }}>
                <div className="news-text-box" style={{ height: '100%' }}>
                  <a href="#">
                    <h4 className="news-title">Başvuru Koşulları</h4>
                  </a>
                  <p className="para">
                    Demo Day'e başvuracak Startup'ların,
                    <ul>
                      <li>Lojistik sektörüne yönelik özgün çözümlerinin olması (en az MVP Öncesi, Prototip/ Yazılım geliştirme seviyesinde)</li>
                      <li>Başvuruyu yapacak kişinin şirkette hissedar olması ve yönetimde aktif rol alıyor olması gerekmektedir.</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4">
              <div className="news-item" style={{ height: '100%' }}>
                <div className="news-text-box" style={{ height: '100%' }}>
                  <a href="#">
                    <h4 className="news-title">Başvuru Alanları</h4>
                  </a>
                  <p className="para">
                    Lojistik, tedarik zinciri, perakende, robotik, mobilite, yapay zeka, blockchain, iş zekası gibi alanlarda yenilikçi çözümler sunuyorsan, Dinçer Lojistik Startup Demo Day'e başvurunu bekliyoruz.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="col-lg-4">
              <div className="news-item" style={{ height: '100%' }}>
                <div className="news-text-box" style={{ height: '100%' }}>
                  <a href="#">
                    <h4 className="news-title">Başvuru Değerlendirme Kriterleri</h4>
                  </a>
                  <p className="para">
                    Başvurularda startup faaliyetlerinin lojistik sektörüne yönelik yenilikçi yaklaşımları, büyüme potansiyeli, performansı, yatırıma uygunluğu ve ekosisteme etkisi değerlendirilecektir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> 

      {/* BAŞVURU YAP */}


      {/* JÜRİ */}
      <section className="text-center" id="section-2">
        <div className="container">
          {/*Row*/}
          <div className="row">
            <div className="col-md-12">
              <div className="heading-area mx-570 mb-5">
                <h2 className="title">JÜRİ</h2>
              </div>
            </div>
          </div>
          {/*Row*/}
          <div className="row wow fadeInUp">
            {juries.map((jury, index) => (
              <div className="col-md-4 mb-4">
                <div className='m-2 h-100'>
                  <div className="team-item">
                    <div className='team-image' style={{
                      width: 200,
                      height: 200,
                      borderRadius: '100%',
                      overflow: 'hidden',
                      background: `url(${jury.photo}) no-repeat center center`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}>
                    </div>

                    <div className="name mb-3">
                      <h6 style={{
                        color: '#000'
                      }}>{jury.name}</h6>
                    </div>
                    {jury.title && <p className="designation mb-2">{jury.title}</p>}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* JÜRİ */}

      {/* ÖDÜLLER */}

      <section className="bg-light" id="section-3">
        <div className="container container-fluid">
          {/*Row*/}
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="heading-area mx-570 pb-lg-5 mb-5">
                <h2 className="title mb-0">ÖDÜLLER</h2>
              </div>
            </div>
          </div>
          {/*Row*/}
          <div className="row wow fadeInUp justify-content-center">
            {/*News Item*/}
            <div className="col-lg-4 mb-4">
              <div className="news-item">
                <div className="news-text-box">
                  <a>
                    <h4 className="news-title text-center font-weight-bold" style={{
                      fontSize: "3rem",
                      color: 'var(--purple)'
                    }}>1</h4>
                  </a>
                  <a >
                    <h4 className="news-title text-center" style={{
                      fontSize: "3.5rem",
                      fontWeight: 'bolder',
                      fontFamily: 'Oxanium',
                      color: 'var(--purple)'
                    }}>150.000₺</h4>
                  </a>
                </div>
              </div>
            </div>
            {/*News Item*/}
            <div className="col-lg-4 mb-4">
              <div className="news-item">
                <div className="news-text-box">
                  <a>
                    <h4 className="news-title text-center font-weight-bold" style={{
                      fontSize: "3rem",
                      color: 'var(--purple)'
                    }}>2</h4>
                  </a>
                  <a>
                    <h4 className="news-title text-center" style={{
                      fontSize: "3.5rem",
                      fontWeight: 'bolder',
                      fontFamily: 'Oxanium',
                      color: 'var(--purple)'
                    }}>100.000₺</h4>
                  </a>
                </div>
              </div>
            </div>
            {/*News Item*/}
            <div className="col-lg-4 mb-4">
              <div className="news-item">
                <div className="news-text-box">
                  <a>
                    <h4 className="news-title text-center font-weight-bold" style={{
                      fontSize: "3rem",
                      color: 'var(--purple)'
                    }}>3</h4>
                  </a>
                  <a>
                    <h4 className="news-title text-center" style={{
                      fontSize: "3.5rem",
                      fontWeight: 'bolder',
                      fontFamily: 'Oxanium',
                      color: 'var(--purple)'
                    }}>75.000₺</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ÖDÜLLER */}

      {/* PROGRAM */}


      <section className="bg-light" id="section-4">
        <div className="container container-fluid">
          {/*Row*/}
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="heading-area mx-570 pb-lg-5 mb-5">
                <h2 className="title mb-0">PROGRAM</h2>
              </div>
            </div>
          </div>
          {/*Row*/}
          <div className="row wow fadeInUp">
            <ProgramTimeLine />
          </div>
        </div>
      </section>

      {/* PROGRAM */}

      {/*About Us*/}
      <section className="pb-0 d-none" id="section-1222">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow fadeInLeft">
              <div className="heading-area">
                <span className="sub-title">We are megaone company</span>
                <h2 className="title">We are making <span className="alt-color js-rotating">design, ideas</span> better
                  for
                  everyone</h2>
                <p className="para">There are many variations of passages of Lorem Ipsum available, but the majority
                  have suffered alteration in some form, by injected humour, or randomised words which don't
                  look
                  even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
                <a className="btn btn-large btn-rounded btn-pink btn-hvr-blue mt-3" href="javascript:void(0);">Learn
                  More
                  <div className="btn-hvr-setting">
                    <ul className="btn-hvr-setting-inner">
                      <li className="btn-hvr-effect" />
                      <li className="btn-hvr-effect" />
                      <li className="btn-hvr-effect" />
                      <li className="btn-hvr-effect" />
                    </ul>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInRight">
              <div className="half-img mt-5 pt-4 mt-lg-0 pt-lg-0">
                <img alt="vector" src="img/vector-art-2.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*About Us End*/}
      {/*Services Start*/}
      <section id="section-2" className='d-none'>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="process-wrapp">
                <span className="pro-step blue"><i aria-hidden="true" className="fas fa-folder-open" /></span>
                <h4 className="service-heading">World leader in consulting and finance</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="process-wrapp">
                <span className="pro-step midnight"><i aria-hidden="true" className="fas fa-code" /></span>
                <h4 className="service-heading">A focused team with a specialized skill</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="process-wrapp">
                <span className="pro-step purple"><i aria-hidden="true" className="fas fa-cog" /></span>
                <h4 className="service-heading">Trusted and professional advisors for you</h4>
              </div>
            </div>
            <div className="col-md-3">
              <div className="process-wrapp">
                <span className="pro-step pink"><i aria-hidden="true" className="fas fa-edit" /></span>
                <h4 className="service-heading">Experience to give you a better results</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Services End*/}
      {/*Counters Start*/}
      <section className="gradient-bg2 d-none" id="section-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pl-lg-4 order-lg-2 wow fadeInRight">
              <div className="heading-area">
                <span className="sub-title text-white">We are megaone company</span>
                <h2 className="title text-white">We have done some great <span className="js-rotating">stuff,
                  idea</span>.
                </h2>
                <p className="para text-white">There are many variations of passages of Lorem Ipsum available, but
                  the
                  majority have suffered alteration in some form, by injected.</p>
              </div>
              <ul className="counter-list list-unstyled">
                <li className="counter-item">
                  <i aria-hidden="true" className="fas fa-users text-white" />
                  <h6 className="counter-number text-white"><span className="count">500</span>+</h6>
                  <p className="sub-title text-white">Happy Clients</p>
                </li>
                <li className="counter-item">
                  <i aria-hidden="true" className="fas fa-list-alt text-white" />
                  <h6 className="counter-number text-white"><span className="count">1074</span>+</h6>
                  <p className="sub-title text-white">Lines Of Code</p>
                </li>
                <li className="counter-item">
                  <i aria-hidden="true" className="fas fa-award text-white" />
                  <h6 className="counter-number text-white"><span className="count">600</span>+</h6>
                  <p className="sub-title text-white">Project Completed</p>
                </li>
              </ul>
            </div>
            <div className="col-lg-6 wow fadeInLeft">
              <div className="half-img mt-5 pt-4 mt-lg-0 pt-lg-0">
                <img alt="vector" src="img/vector-art-3.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Counters End*/}

      {/*Parallax Start*/}
      <section className="bg-light d-none">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 wow fadeInLeft">
              <div className="heading-area">
                <span className="sub-title">We are megaone company</span>
                <h2 className="title"><span className="main-color js-rotating">Robust Design, Creative Idea</span> and
                  Development Solutions</h2>
                <p className="para">There are many variations of passages of Lorem Ipsum available, but the majority
                  have suffered alteration in some form, by injected humour, or randomised words which don't
                  look
                  even slightly believable. If you are going to use a passage of Lorem Ipsum.</p>
                <a className="btn btn-large btn-rounded btn-blue btn-hvr-pink mt-3" href="javascript:void(0);">Learn
                  More
                  <div className="btn-hvr-setting">
                    <ul className="btn-hvr-setting-inner">
                      <li className="btn-hvr-effect" />
                      <li className="btn-hvr-effect" />
                      <li className="btn-hvr-effect" />
                      <li className="btn-hvr-effect" />
                    </ul>
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInRight">
              <div className="half-img mt-5 pt-4 mt-lg-0 pt-lg-0">
                <img alt="image" src="img/vector-art-4.png" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Parallax End*/}
      {/*Portfolio Start*/}
      <section className="cube-portfolio1 text-center no-transition d-none" id="portfolio">
        <div className="container">
          {/*Row*/}
          <div className="row">
            <div className="col-md-12">
              <div className="heading-area mx-570 mb-lg-4 mb-3">
                <span className="sub-title">We are megaone company</span>
                <h2 className="title">We have <span className="alt-color js-rotating">designed, developed</span> some
                  great
                  projects</h2>
                <p className="para">There are many variations of passages of Lorem Ipsum available, but the majority
                  have suffered alteration in some form, by injected.</p>
              </div>
            </div>
          </div>
          {/*Row*/}
          <div className="row wow fadeIn">
            <div className="col-md-12">
              {/*Portfolio Filters*/}
              <div className="cbp-l-filters-button" id="js-filters-mosaic-flat">
                <div className="cbp-filter-item-active cbp-filter-item" data-filter="*">All</div>
                <span className="text-blue">/</span>
                <div className="cbp-filter-item" data-filter=".graphic">Graphic Design</div>
                <span className="text-blue"> / </span>
                <div className="cbp-filter-item" data-filter=".web-design">Web design</div>
                <span className="text-blue"> / </span>
                <div className="cbp-filter-item" data-filter=".graphic">SEO</div>
                <span className="text-blue"> / </span>
                <div className="cbp-filter-item" data-filter=".marketing">Marketing</div>
              </div>
              {/*Portfolio Items*/}
              <div className="cbp cbp-l-grid-mosaic-flat" id="js-grid-mosaic-flat">
                <div className="cbp-item web-design graphic">
                  <a className="cbp-caption cbp-lightbox" href="img/work-1.jpg">
                    <div className="cbp-caption-defaultWrap">
                      <img alt="port-1" src="img/work-1.jpg" />
                    </div>
                    <div className="cbp-caption-activeWrap" />
                    <div className="cbp-l-caption-alignCenter center-block">
                      <div className="cbp-l-caption-body">
                        <div className="plus" />
                        <h5 className="text-white mb-1">Latest Work</h5>
                        <p className="text-white">See Our Amazing Work</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="cbp-item seo marketing">
                  <a className="cbp-caption cbp-lightbox" href="img/work-2.jpg">
                    <div className="cbp-caption-defaultWrap">
                      <img alt="port-2" src="img/work-2.jpg" />
                    </div>
                    <div className="cbp-caption-activeWrap" />
                    <div className="cbp-l-caption-alignCenter center-block">
                      <div className="cbp-l-caption-body">
                        <div className="plus" />
                        <h5 className="text-white mb-1">Latest Work</h5>
                        <p className="text-white">See Our Amazing Work</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="cbp-item seo marketing">
                  <a className="cbp-caption cbp-lightbox" href="img/work-3.jpg">
                    <div className="cbp-caption-defaultWrap">
                      <img alt="port-4" src="img/work-3.jpg" />
                    </div>
                    <div className="cbp-caption-activeWrap" />
                    <div className="cbp-l-caption-alignCenter center-block">
                      <div className="cbp-l-caption-body">
                        <div className="plus" />
                        <h5 className="text-white mb-1">Latest Work</h5>
                        <p className="text-white">See Our Amazing Work</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="cbp-item graphic seo marketing">
                  <a className="cbp-caption cbp-lightbox" href="img/work-4.jpg">
                    <div className="cbp-caption-defaultWrap">
                      <img alt="port-3" src="img/work-4.jpg" />
                    </div>
                    <div className="cbp-caption-activeWrap" />
                    <div className="cbp-l-caption-alignCenter center-block">
                      <div className="cbp-l-caption-body">
                        <div className="plus" />
                        <h5 className="text-white mb-1">Latest Work</h5>
                        <p className="text-white">See Our Amazing Work</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="cbp-item web-design graphic">
                  <a className="cbp-caption cbp-lightbox" href="img/work-5.jpg">
                    <div className="cbp-caption-defaultWrap">
                      <img alt="port-5" src="img/work-5.jpg" />
                    </div>
                    <div className="cbp-caption-activeWrap" />
                    <div className="cbp-l-caption-alignCenter center-block">
                      <div className="cbp-l-caption-body">
                        <div className="plus" />
                        <h5 className="text-white mb-1">Latest Work</h5>
                        <p className="text-white">See Our Amazing Work</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="cbp-item seo marketing graphic ">
                  <a className="cbp-caption cbp-lightbox" href="img/work-6.jpg">
                    <div className="cbp-caption-defaultWrap">
                      <img alt="port-6" src="img/work-6.jpg" />
                    </div>
                    <div className="cbp-caption-activeWrap" />
                    <div className="cbp-l-caption-alignCenter center-block">
                      <div className="cbp-l-caption-body">
                        <div className="plus" />
                        <h5 className="text-white mb-1">Latest Work</h5>
                        <p className="text-white">See Our Amazing Work</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="cbp-item web-design seo">
                  <a className="cbp-caption cbp-lightbox" href="img/work-7.jpg">
                    <div className="cbp-caption-defaultWrap">
                      <img alt="port-7" src="img/work-7.jpg" />
                    </div>
                    <div className="cbp-caption-activeWrap" />
                    <div className="cbp-l-caption-alignCenter center-block">
                      <div className="cbp-l-caption-body">
                        <div className="plus" />
                        <h5 className="text-white mb-1">Latest Work</h5>
                        <p className="text-white">See Our Amazing Work</p>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="cbp-item web-design graphic">
                  <a className="cbp-caption cbp-lightbox" href="img/work-8.jpg">
                    <div className="cbp-caption-defaultWrap">
                      <img alt="port-8" src="img/work-8.jpg" />
                    </div>
                    <div className="cbp-caption-activeWrap" />
                    <div className="cbp-l-caption-alignCenter center-block">
                      <div className="cbp-l-caption-body">
                        <div className="plus" />
                        <h5 className="text-white mb-1">Latest Work</h5>
                        <p className="text-white">See Our Amazing Work</p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Portfolio End*/}
      {/*Testimonial Start*/}
      <section className="gradient-bg1 text-center d-none" id="clients">
        <div className="container">
          {/*Row*/}
          <div className="row">
            <div className="col-md-12">
              <div className="heading-area mx-570 mb-5">
                <h2 className="title text-white m-0">Some <span className="js-rotating">great, ideal</span> words from
                  our clients</h2>
              </div>
            </div>
          </div>
          {/*Row*/}
          <div className="row">
            <div className="col-md-12">
              <div className="owl-carousel wow zoomIn" id="testimonial-slider">
                <div className="item">
                  <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    tincidunt
                    egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                    ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur
                    adipiscing
                    elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                  <h5 className="name gradient-text1">David Villas</h5>
                  <span className="designation">Designer, Company Name</span>
                  <ul className="ratings list-unstyled">
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                  </ul>
                </div>
                <div className="item">
                  <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    tincidunt
                    egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                    ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur
                    adipiscing
                    elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                  <h5 className="name gradient-text1">David Villas</h5>
                  <span className="designation">Designer, Company Name</span>
                  <ul className="ratings list-unstyled">
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                  </ul>
                </div>
                <div className="item">
                  <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    tincidunt
                    egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                    ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur
                    adipiscing
                    elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                  <h5 className="name gradient-text1">David Villas</h5>
                  <span className="designation">Designer, Company Name</span>
                  <ul className="ratings list-unstyled">
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                  </ul>
                </div>
                <div className="item">
                  <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    tincidunt
                    egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                    ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur
                    adipiscing
                    elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                  <h5 className="name gradient-text1">David Villas</h5>
                  <span className="designation">Designer, Company Name</span>
                  <ul className="ratings list-unstyled">
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                  </ul>
                </div>
                <div className="item">
                  <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    tincidunt
                    egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                    ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur
                    adipiscing
                    elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                  <h5 className="name gradient-text1">David Villas</h5>
                  <span className="designation">Designer, Company Name</span>
                  <ul className="ratings list-unstyled">
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                  </ul>
                </div>
                <div className="item">
                  <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    tincidunt
                    egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                    ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur
                    adipiscing
                    elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                  <h5 className="name gradient-text1">David Villas</h5>
                  <span className="designation">Designer, Company Name</span>
                  <ul className="ratings list-unstyled">
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                  </ul>
                </div>
                <div className="item">
                  <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    tincidunt
                    egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                    ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur
                    adipiscing
                    elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                  <h5 className="name gradient-text1">David Villas</h5>
                  <span className="designation">Designer, Company Name</span>
                  <ul className="ratings list-unstyled">
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                  </ul>
                </div>
                <div className="item">
                  <p className="para">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                    tincidunt
                    egestas nunc, at pellentesque arcu sollicitudin et. Aliquam hendrerit diam quis ipsum
                    ultricies, quis ultricies arcu suscipit. Lorem ipsum dolor sit amet, consectetur
                    adipiscing
                    elit. Fusce ut diam quis nulla faucibus venenatis. </p>
                  <h5 className="name gradient-text1">David Villas</h5>
                  <span className="designation">Designer, Company Name</span>
                  <ul className="ratings list-unstyled">
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                    <li><i aria-hidden="true" className="fas fa-star" /></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/*Testimonial Thumbs*/}
          <div className="owl-dots" id="testimonials-avatar">
            {/*data-position[top,right,bottom,left]*/}
            <button className="owl-dot active" data-position="22%,auto,auto,5%">
              <img alt="image" src="img/avatar-1.png" /></button>
            <button className="owl-dot active" data-position="30%,auto,auto,16%">
              <img alt="image" src="img/avatar-2.png" /></button>
            <button className="owl-dot active" data-position="auto,auto,38%,7%">
              <img alt="image" src="img/avatar-3.png" /></button>
            <button className="owl-dot active" data-position="auto,auto,23%,18%">
              <img alt="image" src="img/avatar-7.png" /></button>
            {/*data-position[top,right,bottom,left]*/}
            <button className="owl-dot active" data-position="20%,19%,auto,auto">
              <img alt="image" src="img/avatar-5.png" /></button>
            <button className="owl-dot active" data-position="28%,6%,auto,auto">
              <img alt="image" src="img/avatar-6.png" /></button>
            <button className="owl-dot active" data-position="40%,15%,auto,auto">
              <img alt="image" src="img/avatar-4.png" /></button>
            <button className="owl-dot active" data-position="auto,21%,22%,auto">
              <img alt="image" src="img/avatar-2.png" /></button>
          </div>
        </div>
      </section>
      {/*Testimonial End*/}
      {/*Blog Start*/}
      <section className="bg-light d-none" id="blog">
        <div className="container">
          {/*Row*/}
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="heading-area mx-570 pb-lg-5 mb-5">
                <span className="sub-title">We are megaone company</span>
                <h2 className="title mb-0">Our <span className="alt-color js-rotating">latest blogs,recent news</span>
                  will
                  keep
                  everyone updated</h2>
              </div>
            </div>
          </div>
          {/*Row*/}
          <div className="row wow fadeInUp">
            {/*News Item*/}
            <div className="col-lg-4">
              <div className="news-item">
                <img alt="image" className="news-img" src="img/blog-news-1.jpg" />
                <div className="news-text-box">
                  <span className="date main-color">October 29, 2020</span>
                  <a href="blog-list.html">
                    <h4 className="news-title">Web design is fun</h4>
                  </a>
                  <p className="para">Lorem ipsum dolor sit amet consectetur adipiscing elit ipsum dolor sit am...
                  </p>
                  <a className="author d-flex align-items-center" href="javascript:void(0);">
                    <img alt="image" className="author-img bg-blue" src="img/avatar-1.png" />
                    <h5 className="author-name">Hena Sword</h5>
                  </a>
                </div>
              </div>
            </div>
            {/*News Item*/}
            <div className="col-lg-4">
              <div className="news-item">
                <img alt="image" className="news-img" src="img/blog-news-2.jpg" />
                <div className="news-text-box">
                  <span className="date main-color">October 29, 2020</span>
                  <a href="blog-list.html">
                    <h4 className="news-title">Future of websites</h4>
                  </a>
                  <p className="para">Lorem ipsum dolor sit amet consectetur adipiscing elit ipsum dolor sit am...
                  </p>
                  <a className="author d-flex align-items-center" href="javascript:void(0);">
                    <img alt="image" className="author-img bg-purple" src="img/avatar-2.png" />
                    <h5 className="author-name">David Villas</h5>
                  </a>
                </div>
              </div>
            </div>
            {/*News Item*/}
            <div className="col-lg-4">
              <div className="news-item">
                <img alt="image" className="news-img" src="img/blog-news-3.jpg" />
                <div className="news-text-box">
                  <span className="date main-color">October 29, 2020</span>
                  <a href="javascript:void(0);">
                    <h4 className="news-title">Digital marketing</h4>
                  </a>
                  <p className="para">Lorem ipsum dolor sit amet consectetur adipiscing elit ipsum dolor sit am...
                  </p>
                  <a className="author d-flex align-items-center" href="javascript:void(0);">
                    <img alt="image" className="author-img bg-pink" src="img/avatar-5.png" />
                    <h5 className="author-name">Jhon Walker</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Blog End*/}
      {/*Client Map*/}
      <section className="gradient-bg2" id="section-6">
        <div className='container text-center'>
          <h1 className="text-white font-weight-bold mb-7">Sponsorlarımız</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/*Client Slider*/}
              <div className="owl-carousel partners-slider">
                 <div className="logo-item">
                  <a href="https://www.fixcloud.com.tr/" target="_blank">
                    <img alt="client-logo" src="img/sponsor/fixcloud.png" />
                  </a>
                </div>
                <div className="logo-item">
                  <a href="https://www.rint.com.tr/" target="_blank">
                    <img alt="client-logo" src="img/sponsor/rint.png" />
                  </a>
                </div>
                <div className="logo-item">
                  <a href="https://www.doa.com.tr/" target="_blank">
                    <img alt="client-logo" src="img/sponsor/doa.png" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*Client End*/}
      {/*Map Start*/}
      <div className="p-0 gradient-bg2 map-area d-none">
        <div className="container">
          {/*Map Initialize*/}
          <div className="full-map" id="map" />
        </div>
      </div>
      {/*Map End*/}
      {/*Contact Start*/}
      <ContactForm />
      {/*Contact End*/}

    </>
  )
}




function ContactForm() {

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')
  const [loading, setLoading] = useState(false);

  const sendMessage = async () => {
    setLoading(true);
    var res = await Api.createContact({
      name,
      email,
      message,
      subject
    });

    if (res.success) {
      toast.success(<>
        <h5 className="text-white">Başarılı</h5>
        {"Mesajınız başarıyla gönderildi."}
      </>);
    }
    else {
      toast.error(<>
        <h5 className="text-white">Hata</h5>
        {"Mesajınız gönderilirken sistemsel bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."}
      </>,);
    }
    setLoading(false);
  };


  return (<>
    <section className="contact-us" id="section-5">
      <div className="container">
        <div className="row align-items-top">
          <div className="col-lg-5 order-lg-2 wow fadeInRight">
            <div className="contact-detail">
              <div className="contact-dots" data-dots />
              {/*Heading*/}
              <div className="heading-area pb-5">
                <h2 className="title mt-0 pb-1">Genel Merkez</h2>
                <p className="para">Aydınevler Mah. İnönü Cad. Ofis Park Plaza No:20 Kat:3 Maltepe/İstanbul</p>
              </div>
              {/*Address*/}
              <ul className="address list-unstyled">
                <li>
                  <span className="address-icon gradient-text2"><i aria-hidden="true" className="fas fa-map-marker-alt" /></span>
                  <span className="address-text"><a target="_blank" href="https://www.google.com/maps/place/Din%C3%A7er+Lojistik+A.%C5%9E./@40.9095285,29.1888852,17z/data=!4m10!1m2!2m1!1zRXNlbnRlcGUsIEVzZW50ZXBlIE1haC4gxLBuw7Zuw7wgQ2FkLiBObzoxNDcgS2FydGFsa3VsZSwgxLBuw7Zuw7wgQ2QuIEQ6MTQuS2F0LCAzNDg3MCBLYXJ0YWwvxLBzdGFuYnVs!3m6!1s0x14cb23bc73d254b9:0x7b1a91e15cca3939!8m2!3d40.909453!4d29.1910474!15sCmZFc2VudGVwZSwgRXNlbnRlcGUgTWFoLiDEsG7Dtm7DvCBDYWQuIE5vOjE0NyBLYXJ0YWxrdWxlLCDEsG7Dtm7DvCBDZC4gRDoxNC5LYXQsIDM0ODcwIEthcnRhbC_EsHN0YW5idWxaXyJdZXNlbnRlcGUgZXNlbnRlcGUgbWFoIGluw7Zuw7wgY2FkIG5vIDE0NyBrYXJ0YWxrdWxlIGluw7Zuw7wgY2QgZCAxNCBrYXQgMzQ4NzAga2FydGFsIGlzdGFuYnVskgERbG9naXN0aWNzX3NlcnZpY2XgAQA!16s%2Fg%2F11cjpbrqjk">
                   Aydınevler Mah. İnönü Cad. Ofis Park Plaza No:20 Kat:3 Maltepe/İstanbul
                  </a></span>
                </li>
                <li>
                  <span className="address-icon gradient-text2"><i aria-hidden="true" className="fas fa-phone-volume" /></span>
                  <span className="address-text"><a href="tel:4442874">444 2 874</a></span>
                </li>
                <li>
                  <span className="address-icon gradient-text2"><i aria-hidden="true" className="fas fa-paper-plane" /></span>
                  <span className="address-text"><a className="mr-3 alt-color" href="mailto:info@dincerlojistik.com.tr">info@dincerlojistik.com.tr</a></span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-7 mt-4 pt-3 mt-lg-0 pt-lg-0 wow fadeInLeft">
            {/*Heading*/}
            <div className="heading-area pb-2">
              <h2 className="title mt-0">Bizimle İletişime Geçin</h2>
            </div>
            {/*Contact Form*/}
            <form className="contact-form" id="contact-form-data">
              <div className="row">
                {/*Result*/}
                <div className="col-12" id="result" />
                {/*Left Column*/}
                <div className="col-md-5">
                  <div className="form-group">
                    <input className="form-control" placeholder="Ad Soyad" required type="text" value={name} onChange={(evt) => setName(evt.target.value)} />
                  </div>
                  <div className="form-group">
                    <input className="form-control" placeholder="E-Posta" required type="email" value={email} onChange={(evt) => setEmail(evt.target.value)} />
                  </div>
                  <div className="form-group">
                    <input className="form-control" placeholder="Konu" required type="text" value={subject} onChange={(evt) => setSubject(evt.target.value)} />
                  </div>
                </div>
                {/*Right Column*/}
                <div className="col-md-7">
                  <div className="form-group">
                    <textarea className="form-control" placeholder="Mesaj" defaultValue={""} value={message} onChange={(evt) => setMessage(evt.target.value)} />
                  </div>
                </div>
                {/*Button*/}
                <div className="col-md-12">
                  <a className="btn btn-large btn-rounded btn-purple btn-hvr-blue d-block mt-4 contact_btn" href="javascript:void(0);" onClick={sendMessage}><i className="fa fa-spinner fa-spin mr-2 d-none" aria-hidden="true" /><b>Gönder</b>
                    <div className="btn-hvr-setting">
                      <ul className="btn-hvr-setting-inner">
                        <li className="btn-hvr-effect" />
                        <li className="btn-hvr-effect" />
                        <li className="btn-hvr-effect" />
                        <li className="btn-hvr-effect" />
                      </ul>
                    </div>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </>);
}

function ProgramTimeLine() {
  const events1 = [
    { status: 'Lansman', subtitle: 'Başvuru Toplama', date: '25 Kasım', color: '#9C27B0' },
    { subtitle: 'Başvuru Kapanış', status: 'Başvuru Kapanış', date: '15 Aralık', color: '#FF9800' },
    { status: 'Başvuru Değerlendirme ve Ön Jüri', date: '17 Aralık', color: '#673AB7' },    
    { status: 'Demoday', date: '20 Aralık', color: '#607D8B' }
  ];

  const events2 = [
    '2021', '2022', '2023', '2024'
  ];

  const customizedMarker = (item) => {
    return (
      <span className="custom-marker shadow-1" style={{ backgroundColor: item.color }}>
        <i className={item.icon}></i>
      </span>
    );
  };

  const customizedContent = (item) => {
    return (
      <PrimeCard title={item.status} subTitle={item.date}>
        {item.subtitle && <p>{item.subtitle}</p>}
      </PrimeCard>
    );
  };

  return (<>
    <Timeline value={events1} align="alternate" className="timeline-custom" marker={customizedMarker} content={customizedContent} />

  </>)
}