import logo from './logo.svg';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import { ToastContainer, toast } from 'react-toastify';
import { AppRouter } from './router/AppRouter';
import { useEffect } from 'react';
import AppRoot from './Layout/AppRoot';
import AppFooter from './Layout/AppFooter';
import {BrowserRouter as Router} from 'react-router-dom'

function App() {

  return (
    <>
      <Router>
        <AppRoot />
        <AppRouter />
        <ToastContainer theme="colored" position='top-center' />
        <AppFooter />
      </Router>

    </>
  );
}

export default App;
