import React, { useEffect, useState } from 'react'
import { Api, RegistrationForm } from '../Api/Api';
import { PageSlider } from '../Layout/PageSlider'
import { toast } from 'react-toastify'
import { PageHeader } from '../Layout/PageHeader';

export default function RegisterPage() {

  /**
   * @type {[RegistrationForm, (form: RegistrationForm) => void]}
   */
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [kvkk, setKvkk] = useState(false);

  //  const filledFieldCount = Object.keys(state).length;
  const isFormValid = kvkk;

  const chooseFile = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'application/pdf';
    input.onchange = e => {
      const file = e.target.files[0];
      setFile(file);
    };
    input.click();
  };

  const registerOnClick = async () => {
    setLoading(true);
    const result = await Api.createRegistration({ ...state, document: file });
    setLoading(false);
    if (result.success) {
      toast.success(<>
        <h5 className='text-white'>Başarılı</h5>
        {"Kaydınız başarıyla alındı."}
      </>)
    } else {
      toast.error(<>
        <h5 className='text-white'>Kayıt Başarısız</h5>
        {"Kayıt işleminde bir hata oluştu. Lütfen daha sonra tekrar deneyiniz."}
      </>);
    }
  };



  return (<>
    <PageHeader />
    <div className="container" style={{
      position: 'relative',
      zIndex: 100
    }}>
      <div className="row">
        <div className="col-12">
          <div className="card" style={{
            marginTop: 40,
            marginBottom: 40,
            borderRadius: 12,
            padding: 20,
            boxShadow: '0 10px 50px 0 rgba(0, 0, 0, 0.2)'
          }}>
            <div className="text-center">
              <h5 style={{ fontWeight: 'bold' }}>
                GİRİŞİM BAŞVURU FORMU
              </h5>
              <h6>Hayallerinize Yelken Açın</h6>
            </div>
            
            <div className="margin-top-10">
              <InputField value={state.Name} onChange={value => setState({ ...state, Name: value })} label="Girişimin Adı" />
              <InputField value={state.WebSite} onChange={value => setState({ ...state, WebSite: value })} label="Girişimin İnternet Sitesi" />
              <InputField multi value={state.Description} onChange={value => setState({ ...state, Description: value })} label="Girişimin Ne Yapıyor?" />
              <InputField multi value={state.Sector} onChange={value => setState({ ...state, Sector: value })} label="Girişimin içinde Bulunduğu Pazar ve Pazarın Büyümesi" />
              <InputField value={state.Simplify} onChange={value => setState({ ...state, Simplify: value })} label="Girişim Neyi Özgün Yapıyor?" />
              <div className="form-group">
                <label className='font-weight-bold'>Girişimin Seviyesi</label>
                <RadioField value={state.Level} optionValue={1} onChange={value => setState({ ...state, Level: value })} label="MVP Öncesi, Prototip/Yazılım Geliştirme" />
                <RadioField value={state.Level} optionValue={2} onChange={value => setState({ ...state, Level: value })} label="MVP" />
                <RadioField value={state.Level} optionValue={3} onChange={value => setState({ ...state, Level: value })} label="MVP Sonrası, Satış" />
                <RadioField value={state.Level} optionValue={4} onChange={value => setState({ ...state, Level: value })} label="Satış Var, İhracat" />
              </div>
              <div className="form-group">
                <label className='font-weight-bold'>Girişimin Şimdiye Kadar Hiç Yatırım Aldı Mı?</label>
                <RadioField value={state.Investment} optionValue={true} onChange={value => setState({ ...state, Investment: value })} label="Evet" />
                <RadioField value={state.Investment} optionValue={false} onChange={value => setState({ ...state, Investment: value })} label="Hayır" />
              </div>
              <InputField multi value={state.LevelDetail} onChange={value => setState({ ...state, LevelDetail: value })} label="Evet ise hangi seviyede, kaç tur ve hangi tutarlarda yatırım alındı?" />
              <div className='form-group'>
                <label className='font-weight-bold'>Girişimin Cirosu Var Mı?</label>
                <RadioField value={state.Indorsement} optionValue={true} onChange={value => setState({ ...state, Indorsement: value })} label="Evet" />
                <RadioField value={state.Indorsement} optionValue={false} onChange={value => setState({ ...state, Indorsement: value })} label="Hayır" />
              </div>
              <div className='form-group'>
                <label className='font-weight-bold'>Girişim Çalışanların Tamamına veya Bir Kısmına Gelir Yaratıyor Mu?</label>
                <RadioField value={state.IncomeGeneration} optionValue={true} onChange={value => setState({ ...state, IncomeGeneration: value })} label="Evet" />
                <RadioField value={state.IncomeGeneration} optionValue={false} onChange={value => setState({ ...state, IncomeGeneration: value })} label="Hayır" />
              </div>
              <InputField label="Girişimde Tam Zamanlı Çalışan Kişi Sayısı" value={state.FullTimeEmployee} onChange={value => setState({ ...state, FullTimeEmployee: value })} />
              <InputField label="Girişimde Toplam Çalışan Kişi Sayısı (yarı zamanlı, danışman dahi)" value={state.TotalEmployee} onChange={value => setState({ ...state, TotalEmployee: value })} />
              <InputField label="Ad Soyad" value={state.Fullname} onChange={value => setState({ ...state, Fullname: value })} />
              <InputField label="Girişimdeki Rolü" value={state.ProjectRole} onChange={value => setState({ ...state, ProjectRole: value })} />
              <div className="form-group">
                <label className='font-weight-bold'>Girişimdeki Zaman Taahhütü</label>
                <RadioField value={state.TimeCommitment} optionValue={1} onChange={value => setState({ ...state, TimeCommitment: value })} label="Tam Zamanlı" />
                <RadioField value={state.TimeCommitment} optionValue={2} onChange={value => setState({ ...state, TimeCommitment: value })} label="Yarı Zamanlı" />
                <RadioField value={state.TimeCommitment} optionValue={3} onChange={value => setState({ ...state, TimeCommitment: value })} label="Dönemsel" />
                <RadioField value={state.TimeCommitment} optionValue={4} onChange={value => setState({ ...state, TimeCommitment: value })} label="Danışman" />
                <RadioField value={state.TimeCommitment} optionValue={5} onChange={value => setState({ ...state, TimeCommitment: value })} label="Stajyer" />
              </div>
              <InputField label="Başvuranın Mail Adresi" value={state.Email} onChange={value => setState({ ...state, Email: value })} />
              <InputField label="Başvuranın Telefon Numarası" value={state.Phone} onChange={value => setState({ ...state, Phone: value })} />
              <InputField label="Sosyal Medya / Portfolyo Hesabı" value={state.SocialMedia} onChange={value => setState({ ...state, SocialMedia: value })} />
              <div className="form-group">
                <label className='font-weight-bold'>Eğitim Seviyesi</label>
                <RadioField value={state.EducationLevel} optionValue={1} onChange={value => setState({ ...state, EducationLevel: value })} label="Doktora" />
                <RadioField value={state.EducationLevel} optionValue={2} onChange={value => setState({ ...state, EducationLevel: value })} label="Yüksek Lisans" />
                <RadioField value={state.EducationLevel} optionValue={3} onChange={value => setState({ ...state, EducationLevel: value })} label="Lisans" />
                <RadioField value={state.EducationLevel} optionValue={4} onChange={value => setState({ ...state, EducationLevel: value })} label="Ön Lisans" />
                <RadioField value={state.EducationLevel} optionValue={5} onChange={value => setState({ ...state, EducationLevel: value })} label="Lise" />
              </div>
              <InputField label="En Son Mezun Olduğu veya Devam Ettiği Okul Adı" value={state.LastGraduateUni} onChange={value => setState({ ...state, LastGraduateUni: value })} />
              <InputField label="En Son Okuduğu veya Okumaya Devam Ettiği Bölüm Adı" value={state.LastGraduateProgram} onChange={value => setState({ ...state, LastGraduateProgram: value })} />
              <div className='form-group'>
                <label className='font-weight-bold'>Cinsiyet</label>
                <RadioField value={state.Gender} optionValue={1} onChange={value => setState({ ...state, Gender: value })} label="Kadın" />
                <RadioField value={state.Gender} optionValue={2} onChange={value => setState({ ...state, Gender: value })} label="Erkek" />
                <RadioField value={state.Gender} optionValue={3} onChange={value => setState({ ...state, Gender: value })} label="Belirtmek İstemiyorum" />
              </div>
              <InputField label="Doğum Yılı" value={state.Birtyear} onChange={value => setState({ ...state, Birtyear: value })} />
              <InputField label="Ülke / Şehir" value={state.CountryCity} onChange={value => setState({ ...state, CountryCity: value })} />
              <InputField multi label="Ekip Hakkında Bilgi Veriniz" value={state.TeamDetail} onChange={value => setState({ ...state, TeamDetail: value })} />

              <div className='form-group'>
                <label className='font-weight-bold'>Sunum (PDF)</label>
                <div>
                  <label>{file && file.name}</label>
                </div>
                <button className='btn btn-primary rounded' onClick={chooseFile}>Dosya Seç</button>
              </div>

              <div className='form-group' style={{
                cursor: 'pointer',
                userSelect: 'none'
              }}>
                <input type="checkbox" checked={kvkk} onChange={e => setKvkk(e.target.checked)} />
                <a href="/documents/aydinlatma-metni.pdf" target="_blank" className='font-weight-bold ml-2'>Aydınlatma Metni</a><a onClick={() => {
                  setKvkk(!kvkk)
                }}>{" 'ni Okudum ve Onaylıyorum"}</a>
              </div>
            </div>
            

            <button disabled={!isFormValid} className="btn btn-primary rounded" onClick={registerOnClick}>Gönder</button>
          </div>
        </div>
      </div>
    </div>

  </>

  )
}

function InputField({ multi, value, onChange, label }) {
  const [id] = useState(Math.random().toString(36).substring(7));
  return <div className="form-group">
    <label className='font-weight-bold' htmlFor={id}>{label}</label>
    {multi && (<textarea className="form-control" style={{ minHeight: 100 }} id={id} value={value} onChange={e => onChange(e.target.value)} />)}
    {!multi && <input name={id} className="form-control" value={value} onChange={e => onChange(e.target.value)} />}
  </div>;
}

function RadioField({ optionValue, value, onChange, label }) {
  const [id] = useState(Math.random().toString(36).substring(7));
  return <div className="">
    <input style={{ marginBottom: 2 }} type="radio" name={id} placeholder={label} checked={optionValue == value} value={value} onClick={e => onChange(optionValue)} />
    <label style={{ margin: 0, paddingLeft: 5 }} onClick={() => onChange(optionValue)} htmlFor={id}>{label}</label>
  </div>;
}

